import React from 'react';
import './App.css';

function App() {
  return (
    <div className="container">
      <div className="image-wrapper">
        {/* <img src="/assets/images/img.svg" alt="Info" className="svg-image" /> */}
        <h1>Doma!n Bought</h1>
        {/* <p>By <a href="mailto:itsbatman.darkknight@gmail.com" target="_blank">itsbatman.darkknight@gmail.com</a></p> */}

      </div>
    </div>
  );
}

export default App;
